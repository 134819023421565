import { Box, Button, ListItemButton, Modal} from "@mui/material"
import { useCallback, useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import { FaRegTimesCircle } from "react-icons/fa"
import { useDispatch, useSelector } from "react-redux"
import { editBranche, getBranches } from "../../../Components/Redux/Slice/Branches/branches"
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete"
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api"
import Astrisks from '../../../Components/Common/astrisk'
import { errorToast } from "../../../Components/Redux/Slice/Toast-Messages/toastMessage"
import { useGoogleMaps } from "../../../Components/Common/mapLoader"
import { useTranslation } from "react-i18next"
import { resetPage, resetSearch, resetSort } from "../../../Components/Redux/Slice/ResetPagination/resetPagination";
import { useRemoteSort } from "../../../Components/Common/sortHook"

const libraries = ["places"];


const EditBranchModal = ({open,close,data,img,type}) => {
    const {t,i18n} = useTranslation()
    const form = useForm()
    const {register,control,handleSubmit,formState,setValue,getValues,reset} = form
    const {errors} = formState
    const [preview, setPreview] = useState(null);
    const [textareaCounter, settextareaCounter] = useState(0);
    const [textareaArCounter, settextareaArCounter] = useState(0);

    const dispatch = useDispatch()

    const {branches} = useSelector(state=>state)
    const { isLoaded, loadError } = useGoogleMaps();

    // const handleImageChange = (e)=> {
    //     const file = e.target.files[0];
    //     if(file){
    //         const reader = new FileReader()
    //         reader.readAsDataURL(file)
    //         reader.onload = ()=>{
    //             setValue('image', reader.result);
    //             setPreview(reader.result);
    //         }
    //     }
    // }

    useEffect(() => {
        // console.log(type);
        // console.log(data);
    }, [type])
    

    // handle status controller
    const statusList = ['busy','available','not available']
    const [status, setstatus] = useState([]);

    const handleStatusSelectChange = (event) => {
        // console.log(event.target.value);
        const { target: { value },} = event;
        setstatus(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
        );
        setValue('status',typeof value === 'string' ? value.split(',') : value,{shouldValidate:true})
    };

    // handle area controller
    const areaList = ['ksa','cairo','uae']
    const [area, setarea] = useState([]);

    const handleAreaSelectChange = (event) => {
        // console.log(event.target.value);
        const { target: { value },} = event;
        setarea(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
        );
        setValue('area',typeof value === 'string' ? value.split(',') : value,{shouldValidate:true})
    };

    

    useEffect(() => {
        // console.log(textareaCounter);
    }, [textareaCounter,textareaArCounter])


    // map section

    const [map, setMap] = useState(null)
    const {key} = useSelector(state=>state?.map)

    // const {isLoaded} = useJsApiLoader({
    //     id: 'google-map-script',
    //     googleMapsApiKey : key?.data?.GOOGLE_MAP_KEYS,   
    //     libraries
    // })
    // const { isLoaded, loadError } = useGoogleMaps();

    // console.log(data);
    const [center,setcenter] = useState({lat : data?.coordinates?.coordinates[0],lng :  data?.coordinates?.coordinates[1]})

    const [markerPosition,setmarkerPosition] = useState({lat : data?.coordinates?.coordinates[0],lng :  data?.coordinates?.coordinates[1]})
    const [address,setaddress] = useState(data?.address)
    const [error,seterror] = useState(null)

    const onLoad = useCallback(function callback(map) {
        setMap(map)
        }, [])

    const onUnmount = useCallback(function callback(map) {
        setMap(null)
    }, [])

    // console.log(address);
    

    const handleMarkerDragEnd = (event) => {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
    
        setmarkerPosition({ lat, lng });
        // console.log({ lat, lng });
        seterror(null)
        const geocoder = new window.google.maps.Geocoder();
        // console.log([lat,lng]);
        geocoder.geocode({ location: { lat, lng } }, (results, status) => {
        if (status === "OK") {
            if (results[0]) {
            setaddress(results[0].formatted_address);
            setselectedAddress(results[0].formatted_address)
            } else {
            // window.alert("No results found");
            dispatch(errorToast('No results found, Choose another location'))
            }
        } else {
            // window.alert("Geocoder failed due to: " + status);
            dispatch(errorToast(`Geocoder failed due to: ${status}`))
        }
        });
    }
    

    // autocomplete service for the google map
    const autoCompleteRef = useRef(null);

    useEffect(() => {
        if (isLoaded) {
        const autocomplete = new window.google.maps.places.Autocomplete(autoCompleteRef.current);

        autocomplete.addListener("place_changed", () => {
            const place = autocomplete.getPlace();
            const location = place.geometry.location;

            setmarkerPosition({ lat: location.lat(), lng: location.lng() });
            setaddress(place.formatted_address);
            
        });
        }
    }, [isLoaded]);
    

    const [selectedAddress,setselectedAddress] = useState(null)

    const handleSelect = async (selectedAddress) => {
        try {
            const results = await geocodeByAddress(selectedAddress);
            const latLng = await getLatLng(results[0]);
            setaddress(selectedAddress);
            setselectedAddress(selectedAddress)
            setmarkerPosition(latLng);
            setcenter(latLng)
            seterror(null)
        } catch (error) {
            console.error("Error:", error);
        }
        };


    let page = 1,
        row = 10;

        // console.log(data?.coordinates?.coordinates);
        // useEffect(() => {
        //     setValue('address',data?.address)
        //     setValue('latitude',data?.coordinates?.coordinates[0])
        //     setValue('longitude',data?.coordinates?.coordinates[1])
        // }, [setValue,data]);

    const modalSubmitHandler = async (formData)=>{
        // setValue('file',img?.result)
        // console.log(formData);
        // if(selectedAddress !== address){
        //     seterror('select valid address from dropdown or drag and drop marker')
        // } else{
        //     await dispatch(editBranche({id:data?.id,value:formData})).then( ()=>{
        //         dispatch(getBranches({page,row}))
        //         close()
        //     } )
        // }

        // console.log({...formData});
        let values = {
            ...formData,
            address : address || data?.address,
            latitude : markerPosition?.lat || data?.coordinates?.coordinates[0],
            longitude : markerPosition?.lng || data?.coordinates?.coordinates[1]
        }
        
        if((address !== '') && (error === null || error === undefined)){
            await dispatch(editBranche({id:data?.id,value:values})).then( ()=>{
                dispatch(getBranches({page,row})).then( ()=>{
                    reset()
                    close()
                    seterror()
                    dispatch(resetPage());
                    dispatch(resetSearch());
                    dispatch(resetSort({sort:true}))
                })
            } )
        } else {
            seterror('select valid address from dropdown or drag and drop marker')
        }
    }


    // useEffect(() => {
    //     // console.log(address);
    //     setValue('address',address || data?.address)
    //     // setValue('coordinates', {"type": "Point","coordinates": [markerPosition?.lng,markerPosition?.lat],})
    //     setValue('latitude', markerPosition?.lat || data?.coordinates?.coordinates[0])
    //     setValue('longitude', markerPosition?.lng || data?.coordinates?.coordinates[1])
    // }, [address, data?.address, data?.coordinates?.coordinates, markerPosition, selectedAddress, setValue])
    
    // console.log(data);
    return ( 
        <>
            <Modal
                open={open}
                onClose={()=>{
                    close()
                    seterror()
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box 
                    dir={localStorage?.getItem('i18nextLng') === 'ar' ? 'rtl' : 'ltr'}
                    sx={{position:'absolute',top:'11%',left:'50%',transform: 'translate(-50%, -10%)',bgcolor:'background.paper',boxShadow:3,p:4,borderRadius:5,height:'auto'}}>
                    <div className="w-full flex justify-between items-start">
                        <h2 className="text-xl text-mainColor capitalize font-semibold">{type === 'view' ? t('branch.modal.view_title') : t('branch.modal.edit_title')}</h2>

                        <div className="mb-4">
                            <span className="text-mainRed text-xl text-right [&>svg]:ml-auto cursor-pointer" onClick={close}><FaRegTimesCircle /></span>
                        </div>
                    </div>

                    <form className="edit-branch-form-wrapper w-full h-full" onSubmit={handleSubmit(modalSubmitHandler)} noValidate>
                        {/* <div className="edit-branch-top-form-section grid grid-cols-1 md:grid-cols-2 gap-3 gap-x-5">

                            <div className="preview-wrapper shadow-md h-[120px] relative p-2 bg-[#f7f5fd] rounded-md w-full">
                                <div className="absolute -top-5 -right-2 cursor-pointer w-full">
                                    <input type="file" id="file" accept="image/*" {...register('file',{onChange:handleImageChange})} className="absolute right-0 cursor-pointer opacity-1 w-[100px] z-10 opacity-0" />
                                    <span className="block absolute right-0 text-2xl bg-[#F2EEFC] p-1 rounded-md text-mainColor cursor-pointer"><MdOutlineModeEditOutline /></span>
                                </div>
                                {<img src={preview ? preview : img} alt="Preview" className="w-full h-full rounded-md" />}
                            </div>

                            <div className="flex flex-col justify-around bg-[#F3F6F9] py-3 px-6 w-full rounded-md shadow-md text-mainGreen borde">
                                <p><span className="font-bold text-2xl mr-2">{data?.resRevenue}</span> <span className="uppercase text-sm">sar</span></p>
                                <p className="capitalize text-sm">revenue</p>
                            </div>

                            <div className="flex flex-col justify-around bg-[#F3F6F9] py-3 px-6 w-full rounded-md shadow-md text-mainColor border">
                                <p><span className="font-bold text-2xl mr-2">{data?.takeinRevenue}</span> <span className="uppercase text-sm">sar</span></p>
                                <p className="capitalize text-sm">TakeIn Share</p>
                            </div>
                        </div> */}
                        

                        <div className="form-input-wrapper max-h-[415px] overflow-hidden overflow-y-auto mt-6 [&>div]:mb-2 [&>div>label]:capitalize [&>div>label]:text-[#333] [&>div>label]:text-sm [&>div>label]:block [&>div>label]:mb-1 [&>div>label]:font-semibold [&>div>input]:bg-[#f3f6f9] [&>div>input]:w-full [&>div>input]:p-2 [&>div>input]:focus:outline-slate-400 [&>div>input]:rounded-md [&>div>input]:text-mainColor [&>div>input]:mb-1">
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-3 [&>div>label]:capitalize [&>div>label]:text-[#333] [&>div>label]:text-sm [&>div>label]:block [&>div>label]:mb-1 [&>div>label]:font-semibold [&>div>input]:bg-[#f3f6f9] [&>div>input]:w-full [&>div>input]:p-2  [&>div>input]:focus:outline-slate-400 [&>div>input]:rounded-md [&>div>input]:text-mainColor [&>div>input]:mb-1">
                                <div>
                                    <label>{t('branch.modal.branch_name')} <Astrisks /></label>
                                    <input type="text" id="name"
                                        {...register('name',{
                                            required:{
                                                value : true,
                                                message : t('common.required')
                                            },
                                            pattern: {
                                                value: /^[a-zA-Z0-9&\s]+$/,
                                                message: localStorage.getItem('i18nextLng') === 'ar' ? 'مسموح بالاحرف الانجليزيه والارقام وعلامه & فقط' : 'Only English letters, numbers, and & are allowed'
                                            },
                                            minLength : {
                                                value : 3,
                                                message : t('common.min_length',{length : '3'})
                                            },
                                            maxLength : {
                                                value : 50,
                                                message : t('common.max_length',{length : '50'})
                                            }
                                        })}
                                        minLength={3}
                                        maxLength={50}

                                        defaultValue={data?.name}
                                        readOnly={type === 'view'}
                                    />

                                    {type !== 'view' ? errors?.name?.message ? <p className="capitalize text-sm text-danger">{errors?.name?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('branch.modal.branch_name_helper')}</p> : null}
                                </div>

                                <div>
                                    <label>{t('branch.modal.branch_arabic_name')} <Astrisks /></label>
                                    <input type="text" id="nameAr"
                                        {...register('nameAr',{
                                            required:{
                                                value : true,
                                                message : t('common.required')
                                            },
                                            pattern: {
                                                value: /^[\u0621-\u064A\s&0-9]+$/,
                                                message: localStorage.getItem('i18nextLng') === 'ar' ? 'مسموح بالاحرف العربية والمسافات وعلامه & والارقام فقط' : 'Only Arabic letters, spaces, & sign and numbers are allowed'
                                            },
                                            minLength : {
                                                value : 3,
                                                message : t('common.min_length',{length : '3'})
                                            },
                                            maxLength : {
                                                value : 50,
                                                message : t('common.max_length',{length : '50'})
                                            }
                                        })}
                                        defaultValue={data?.nameAr}
                                        readOnly={type === 'view'}
                                        minLength={3}
                                        maxLength={50}
                                    />

                                    {type !== 'view' ? errors?.nameAr?.message ? <p className="capitalize text-sm text-danger">{errors?.nameAr?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('branch.modal.branch_arabic_name_helper')}</p> : null}
                                </div>

                                <div className="md:col-span-2 w-full">
                                    <label>{t('branch.modal.branch_phone_number')} <Astrisks /></label>
                                    <input type="text" id="branchPhoneNumber"
                                        {...register('branchPhoneNumber',{
                                            required:{
                                                value : true,
                                                message : t('common.required')
                                            },
                                            pattern:{
                                                value: /^(\+)?\d+$/,
                                                message: localStorage.getItem('i18nextLng') === 'ar' ? 'مسموح فقط بالارقام وعلامه + بالبدايه فقط' : 'Only numbers and (+) sign in the beginning allowed'
                                            },
                                            minLength : {
                                                value : 3,
                                                message : t('common.min_length',{length : '3'})
                                            },
                                            maxLength : {
                                                value : 15,
                                                message : t('common.max_length',{length : '15'})
                                            }
                                        })}
                                        defaultValue={data?.phoneNumber}
                                        readOnly={type === 'view'}
                                        minLength={3}
                                        maxLength={15}
                                    />

                                    {type !== 'view' ? errors?.branchPhoneNumber?.message ? <p className="capitalize text-[13px] text-danger">{errors?.branchPhoneNumber?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('branch.modal.branch_phone_number_helper')}</p> : null}
                                </div>

                                <div className="md:col-span-2 w-full">
                                    <label>{t('branch.modal.branch_description')}</label>
                                    <div className='relative'>
                                        <textarea 
                                            onKeyUp={(e)=>settextareaCounter(e.target.value.length)}
                                            id="description"
                                            className="w-full  h-auto resize-none rounded-md bg-[#f3f6f9] focus:outline-slate-400 text-mainColor p-2"
                                            {...register('description',{
                                                // required:{
                                                //     value : true,
                                                //     message : t('common.required')
                                                // },
                                                minLength : {
                                                    value : 10,
                                                    message : t('common.min_length',{length : '10'})
                                                },
                                                maxLength : {
                                                    value : 500,
                                                    message : t('common.max_length',{length : '500'})
                                                },
                                                pattern: {
                                                    value: /^[a-zA-Z0-9 !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/,
                                                    message: localStorage.getItem('i18nextLng') === 'ar' ? 'مسموح بالاحرف الانجليزيه والارقام وجميع الرموز الخاصة' : 'Only English letters, numbers, and all special characters are allowed'
                                                },
                                            })}

                                            minLength={10}
                                            maxLength={500}
                                            defaultValue={data?.description}
                                            readOnly={type === 'view'}
                                        >
                                        </textarea>
                                        <p className="absolute bottom-2 right-2 text-[12px] text-[#0000005a]">{500 - textareaCounter}</p>
                                    </div>

                                    {type !== 'view' ? errors?.description?.message ? <p className="capitalize text-sm text-danger">{errors?.description?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('branch.modal.branch_description_helper')}</p> : null}

                                </div>

                                <div className="md:col-span-2 w-full">
                                    <label>{t('branch.modal.branch_arabic_description')}</label>
                                    <div className="relative">
                                        <textarea
                                            onKeyUp={(e)=>settextareaArCounter(e.target.value.length)}
                                            id="descriptionAr"
                                            className="w-full  h-auto resize-none rounded-md bg-[#f3f6f9] focus:outline-slate-400 text-mainColor p-2"
                                            {...register('descriptionAr',{
                                                // required:{
                                                //     value : true,
                                                //     message : t('common.required')
                                                // },
                                                minLength : {
                                                    value : 10,
                                                    message : t('common.min_length',{length : '10'})
                                                },
                                                maxLength : {
                                                    value : 500,
                                                    message : t('common.max_length',{length : '500'})
                                                },
                                                pattern: {
                                                    value: /^[\u0621-\u064A0-9 !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/,
                                                    message: localStorage.getItem('i18nextLng') === 'ar' ? 'مسموح بالأحرف العربية والأرقام وجميع الرموز الخاصة' : 'Only Arabic letters, numbers, and all special characters are allowed'
                                                }
                                            })}

                                            minLength={10}
                                            maxLength={500}
                                            defaultValue={data?.descriptionAr}
                                            readOnly={type === 'view'}
                                        >
                                        </textarea>
                                        <p className="absolute bottom-2 right-2 text-[12px] text-[#0000005a]">{500 - textareaArCounter}</p>
                                    </div>
                                    {errors?.descriptionAr?.message ? <p className="capitalize text-[13px] text-danger">{errors?.descriptionAr?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('branch.modal.branch_arabic_description_helper')}</p>}

                                </div>

                                <div className="md:col-span-2">
                                    <label>{t('branch.modal.branch_address')} <Astrisks /></label>

                                    {isLoaded ?
                                        <PlacesAutocomplete
                                            value={address}
                                            onChange={setaddress}
                                            onSelect={handleSelect}
                                            defaultValue={data?.address}
                                            readOnly={type === 'view'}
                                        >
                                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                            <div className="relative [&>input]:bg-[#f3f6f9] [&>input]:w-full [&>input]:p-2  [&>input]:focus:outline-slate-400 [&>input]:rounded-md [&>input]:text-mainColor [&>input]:mb-1">
                                                <input 
                                                    {...getInputProps({ placeholder: "" })} 
                                                    value={address} 
                                                />
                                                {error ? (
                                                    <p className="text-sm capitalize mt-1" style={{ color: "red" }}>{error}</p>
                                                    ) : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('branch.modal.branch_address_helper')}</p>}
                                                <div className="shadow-md max-h-[170px] overflow-y-auto absolute z-50 w-full bg-white [&>div]:py-1 px-2">
                                                {loading && <div>Loading...</div>}
                                                {suggestions.map((suggestion) => {
                                                    const className = suggestion.active
                                                    ? "suggestion-item--active"
                                                    : "suggestion-item";
                                                    return (
                                                    <div key={suggestion?.id} {...getSuggestionItemProps(suggestion, { className })}>
                                                        {suggestion.description}
                                                    </div>
                                                    );
                                                })}
                                                </div>
                                            </div>
                                            )}
                                        </PlacesAutocomplete> : null  
                                    }

                                    {/* {type !== 'view' ? errors?.address?.message ? <p className="capitalize text-sm text-danger">{errors?.address?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">please enter branch address</p> : null} */}

                                </div>

                                {/* <div>
                                    <label>status</label>
                                    <FormControl sx={{width: '100%' }}>
                                        <Controller
                                            control={control}
                                            name=""
                                            rules={{required:"Choose at least one Option"}}
                                            defaultValue={status || ""}
                                            {...register('status',{
                                                required:{
                                                    value : true,
                                                    message: t('common.required')
                                                }
                                            })}
                                            render={({field})=>(
                                                <Select {...field}
                                                    inputRef={{...register('status')}}
                                                    labelId="demo-multiple-checkbox-label"
                                                    id="status"
                                                    multiple
                                                    value={status}
                                                    onChange={handleStatusSelectChange}
                                                    renderValue={(selected) => selected.join(', ')}
                                                    sx={{'& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input' : {padding:'9px 14px'},'& fieldset':{border : 0},'& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input' : {backgroundColor : '#f3f6f9'}}}
                                                >
                                                    {statusList.map((name) => (
                                                        <MenuItem key={name} value={name}>
                                                            <ListItemText primary={name} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            )}
                                        />
                                        
                                    </FormControl>

                                    {errors?.status?.message ? <p className="capitalize text-sm text-danger">{errors?.status?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">please select resturant status</p>}
                                </div> */}
                            </div>

                            <div className="w-full mt-4">
                                <div className="map">
                                    {/* <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}>
                                        <GoogleMap
                                            mapContainerStyle={{ width: '100%', height: '180px' }}
                                            center={position}
                                            zoom={10}
                                        >
                                            <Marker
                                                position={position}
                                                draggable={true}
                                                onDragEnd={handleMarkerDragEnd}
                                            />
                                        </GoogleMap>
                                    </LoadScript> */}

                                    {isLoaded ? 
                                        <div className="w-full h-full [&>div]:w-full [&>div]:h-[180px]">
                                            <GoogleMap
                                                center={center}
                                                zoom={14}
                                                onLoad={onLoad}
                                                onUnmount={onUnmount}
                                            > 
                                                {/* if the marker is not visible on the map, just remove the strict mode from react if the react version is 18 or above  */}
                                                <Marker
                                                    position={markerPosition}
                                                    draggable={type === 'view' ? false : true}
                                                    onDragEnd={handleMarkerDragEnd}
                                                    // icon={{
                                                    //     url: marker1, // Path to the custom marker image
                                                    //     scaledSize: new window.google.maps.Size(30, 30), // Set the size of the marker
                                                    // }}
                                                />
                                        </GoogleMap> 

                                        </div>
                                        : 
                                        'Loading...'
                                    }
                                </div>
                            </div>

                            {/* <div>
                                <label>area</label>
                                <FormControl sx={{width: '100%' }}>
                                    <Controller
                                        control={control}
                                        name=""
                                        rules={{required:"Choose at least one Option"}}
                                        defaultValue={area || ""}
                                        {...register('area',{
                                            required:{
                                                value : true,
                                                message: t('common.required')
                                            }
                                        })}
                                        render={({field})=>(
                                            <Select {...field}
                                                inputRef={{...register('area')}}
                                                labelId="demo-multiple-checkbox-label"
                                                id="area"
                                                multiple
                                                value={area}
                                                onChange={handleAreaSelectChange}
                                                renderValue={(selected) => selected.join(', ')}
                                                sx={{'& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input' : {padding:'10px 14px'},'& fieldset':{border : 0},'& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input' : {backgroundColor : '#f3f6f9'}}}
                                            >
                                                {areaList.map((name) => (
                                                    <MenuItem key={name} value={name}>
                                                        <ListItemText primary={name} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        )}
                                    />
                                    
                                </FormControl>

                                {errors?.area?.message ? <p className="capitalize text-sm text-danger">{errors?.area?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">please select resturant area</p>}
                            </div> */}

                            {/* <div>
                                <label>branch</label>
                                <input type="text" id="branchRest"
                                {...register('branchRest',{
                                    required:{
                                        value : true,
                                        message : t('common.required')
                                    }
                                })} />

                                {errors?.branchRest?.message ? <p className="capitalize text-sm text-danger">{errors?.branchRest?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">please enter resturant branch</p>}
                            </div> */}

                            {/* <div>
                                <label>Completed Orders</label>
                                <input type="text" id="compOrders"
                                {...register('compOrders',{
                                    required:{
                                        value : true,
                                        message : t('common.required')
                                    }
                                })} />

                                {errors?.compOrders?.message ? <p className="capitalize text-sm text-danger">{errors?.compOrders?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">please enter completed orders</p>}
                            </div> */}
                        </div>

                        <div className={`profile-submit-btn-wrapper text-white flex justify-end ${localStorage.getItem('i18nextLng') === 'ar' ? 'flex-row-reverse' : ''} gap-x-5 [&>div]:w-fit [&>div]:grow-0 [&>div]:p-0 mt-6`}>
                            <Button onClick={()=>{
                                close()
                                reset()
                                seterror()
                            }} 
                            className="justify-center bg-[#442b7e4f]  text-sm font-semibold rounded-md py-3 px-8 capitalize text-white">{(t('branch.modal.close_btn'))}</Button>

                            {type === 'edit' ? <ListItemButton className="justify-center bg-mainColor  text-sm font-semibold rounded-md"><button type="submit" disabled={branches?.loading}  className="disabled:opacity-50 disabled:cursor-not-allowed w-full py-3 px-8">{(t('branch.modal.save_btn'))}</button></ListItemButton> : null}
                        </div>

                    </form>
                </Box>
            </Modal>
        </>
     );
}

export default EditBranchModal;